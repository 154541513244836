import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button } from "@mui/material";
import { DataGrid, enUS, GridActionsCellItem, GridPagination, viVN } from "@mui/x-data-grid";
import { Col, notification, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import baoGiaService from "../../api/bao_gia/baoGiaService";
import FormTimKiemBG from "./FormTimKiemBG";
import { Link, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import Chip from "@material-ui/core/Chip";

function QuanLyBaoGia({ activeKey, dataTrangThaiAll, dmTrangThaiId, setDmTrangThaiId }) {
    const [api, contextHolder] = notification.useNotification();
    const { t, i18n } = useTranslation();
    const [vsFooter, setVsFooter] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [dataSearch, setDataSearch] = useState({
        codeOrName: "",
        loaiThoiGian: null,
        tuNgay: null,
        denNgay: null,
    });
    const [pageSize, setPageSize] = useState(10);
    const navigate = useNavigate();

    useEffect(() => {
        getDataSource({
            maHoacTenBaoGia: dataSearch.codeOrName ? dataSearch.codeOrName : "",
            maBaoGia: "",
            tenBaoGia: "",
            tenKhachHang: "",
            soLuongNguoiLon: 0,
            soLuongTreEm: 0,
            soNgay: 0,
            SoDem: 0,
            ngayTao: dataSearch.loaiThoiGian === 1 ? dataSearch.thoiGianTour : "",
            ngayBatDau: dataSearch.loaiThoiGian === 2 ? dataSearch.thoiGianTour : "",
            ngayKetThuc: dataSearch.loaiThoiGian === 3 ? dataSearch.thoiGianTour : "",
            giaTour: 0,
            dmTrangThaiId: dmTrangThaiId,
            nguoiTaoId: 0, //truyền id
            saleId: 0, //truyền id
            pageIndex: 1,
            pageSize: 10,
        });
    }, [dmTrangThaiId]);

    const getDataSource = (data) => {
        baoGiaService
            .getDanhSachBaoGia(data)
            .then((response) => {
                if (response.status === 200 && response.data.code === "00") {
                    console.log("response.data.data: ", response.data.data);

                    let data = response.data.data?.map((item, idx) => {
                        return {
                            ...item,
                            stt: idx + 1,
                            thoiGianTour: item.ngayKhoiHanh + " - " + item.ngayKetThuc,
                        };
                    });
                    setDataSource(data);
                }
            })
            .catch((err) => {
                console.log("Get data bao gia erorrs " + err);
            });
    };

    let formatter = new Intl.NumberFormat("en-US", {});

    const handleEditBaoGia = (param) => {
        debugger;
        console.log(param);
        navigate("/bao-gia/edit?id=" + param.id);
    };
    const deleteRecord = async (params) => {
        let check = await callDeleteBaoGia(params.id);
        if (check) {
            showNoti("success", t("delete") + " " + t("noti.success").toLowerCase());
        } else {
            showNoti("error", t("delete") + " " + t("noti.error").toLowerCase());
        }
    };
    const showNoti = (type, message) => {
        return api[type]({
            message: t("stour"),
            description: t(message),
            duration: 0,
        });
    };
    const callDeleteBaoGia = async (params) => {
        let response = await baoGiaService.deleteBaoGia({ id: params });
        console.log(response);

        if (response.status === 200 && response.data.code === "00") {
            return true;
        }
        return false;
    };
    const cancelRecord = async (params) => {
        let check = await callCancelBaoGia(params.id);
        if (check) {
            showNoti("success", t("action.cancel") + " " + t("noti.success").toLowerCase());
        } else {
            showNoti("error", t("action.cancel") + " " + t("noti.error").toLowerCase());
        }
    };
    const callCancelBaoGia = async (params) => {
        let response = await baoGiaService.deleteBaoGia({ id: params });
        console.log(response);

        if (response.status === 200 && response.data.code === "00") {
            return true;
        }
        return false;
    };
    const handleSearch = () => {
        const dataReq = {
            maHoacTenBaoGia: dataSearch.codeOrName ? dataSearch.codeOrName : "",
            maBaoGia: "",
            tenBaoGia: "",
            tenKhachHang: "",
            soLuongNguoiLon: 0,
            soLuongTreEm: 0,
            soNgay: 0,
            SoDem: 0,
            ngayTao: dataSearch.loaiThoiGian === 1 ? dataSearch.thoiGianTour : "",
            ngayBatDau: dataSearch.loaiThoiGian === 2 ? dataSearch.thoiGianTour : "",
            ngayKetThuc: dataSearch.loaiThoiGian === 3 ? dataSearch.thoiGianTour : "",
            giaTour: 0,
            dmTrangThaiId: dmTrangThaiId,
            nguoiTaoId: 0, //truyền id
            saleId: 0, //truyền id
            pageIndex: 1,
            pageSize: 10,
        };
        getDataSource(dataReq);
    };

    const handleResetDataSearch = () => {
        setDataSearch({
            codeOrName: "",
            loaiThoiGian: null,
            tuNgay: null,
            denNgay: null,
        });
    };

    const handleChuyenDieuHanh = () => {
        let data = {
            listBgTongQuanId: [],
        };
    };

    const columns = [
        {
            headerName: "STT",
            field: "stt",
            key: "stt",
            align: "center",
            width: 50,
            type: "number",
            headerAlign: "center",
        },
        {
            headerName: t("quoteCode"),
            field: "maBaoGia",
            key: "maBaoGia",
            align: "center",
            width: 180,
            headerAlign: "center",
            renderCell: (params) => (
                <Link to={"/bao-gia/edit?id=" + params.id} style={{ color: "#007cff" }}>
                    {params.value}
                </Link>
            ),
        },
        {
            headerName: t("nameQuote"),
            field: "tenBaoGia",
            key: "tenBaoGia",
            align: "center",
            width: 210,
            headerAlign: "center",
        },
        {
            headerName: t("customerInfor"),
            field: "thongTinKhachHang",
            key: "thongTinKhachHang",
            align: "center",
            width: 240,
            headerAlign: "center",
            renderCell: (params) => (
                <div>
                    <div>
                        <span style={{ fontWeight: 600, color: "#a0a0a0" }}>{t("loaiKH") + ": "}</span>
                        <span>{params.value?.loaiKhachHang}</span>
                    </div>
                    <div>
                        <span style={{ fontWeight: 600, color: "#a0a0a0" }}>{t("tenKH") + ": "}</span>
                        <span>{params.value?.tenKhachHang}</span>
                    </div>
                    <div>
                        <span style={{ fontWeight: 600, color: "#a0a0a0" }}>{t("phone") + ": "}</span>
                        <span>{params.value?.soDienThoai}</span>
                    </div>
                    <div>
                        <span style={{ fontWeight: 600, color: "#a0a0a0" }}>{t("email") + ": "}</span>
                        <span>{params.value?.email}</span>
                    </div>
                </div>
            ),
        },
        {
            headerName: t("tourCost"),
            field: "thongTinTour",
            key: "thongTinTour",
            align: "center",
            width: 300,
            headerAlign: "center",
            renderCell: (params) => (
                <div>
                    <div>
                        <span style={{ fontWeight: 600, color: "#a0a0a0" }}>{t("soNgay") + ": "}</span>
                        <span>{params.value?.soNgay}</span>
                    </div>
                    <div>
                        <span style={{ fontWeight: 600, color: "#a0a0a0" }}>{t("timeTour") + ": "}</span>
                        <span>{params.value?.thoiGianTour}</span>
                    </div>
                    <div>
                        <span style={{ fontWeight: 600, color: "#a0a0a0" }}>{t("tour.vanChuyen.soLuong") + ": "}</span>
                        <span>{params.value?.soLuong}</span>
                    </div>
                    <div>
                        <span style={{ fontWeight: 600, color: "#a0a0a0" }}>{t("priceTour") + ": "}</span>
                        <span>{formatter.format(params.value?.giaTour)}</span>
                    </div>
                </div>
            ),
        },
        {
            headerName: t("perform"),
            field: "nguoiThucHien",
            key: "nguoiThucHien",
            align: "center",
            width: 220,
            headerAlign: "center",
            renderCell: (params) => (
                <div>
                    <div>
                        <span style={{ fontWeight: 600, color: "#a0a0a0" }}>{t("nguoiTao") + ": "}</span>
                        <span>{params.value?.nguoiTao}</span>
                    </div>
                    <div>
                        <span style={{ fontWeight: 600, color: "#a0a0a0" }}>{t("sale") + ": "}</span>
                        <span>{params.value?.sale}</span>
                    </div>
                </div>
            ),
        },
        {
            headerName: t("status"),
            field: "trangThai",
            key: "trangThai",
            align: "center",
            minWidth: 170,
            headerAlign: "center",
            renderCell: ({ value, row }) => {
                if (value === "Tạo mới báo giá") return <Chip icon={<AddIcon />} label={value} color="primary" />;
                else if (value === "Đã hủy") return <Chip icon={<CloseIcon />} label={value} color="error" />;
            },
        },
        {
            headerName: t("date"),
            field: "lanSuaCuoi",
            key: "lanSuaCuoi",
            align: "center",
            width: 180,
            headerAlign: "center",
        },
        {
            field: "actions",
            type: "actions",
            width: 50,
            getActions: (params) => [
                <GridActionsCellItem
                    label={t("tour.taiXuong")}
                    // onClick={viewRecord(params)}
                    showInMenu
                />,
                <GridActionsCellItem label={t("edit")} onClick={() => handleEditBaoGia(params)} showInMenu />,
                <GridActionsCellItem label={t("delete")} onClick={() => deleteRecord(params)} showInMenu />,
                <GridActionsCellItem
                    label={t("nhanBan")}
                    // onClick={viewRecord(params)}
                    showInMenu
                />,
                <GridActionsCellItem
                    label={t("Executivetransfer")}
                    // onClick={viewRecord(params)}
                    showInMenu
                />,
                <GridActionsCellItem label={t("Cancelquote")} onClick={() => cancelRecord(params)} showInMenu />,
                <GridActionsCellItem
                    label={t("viewHistory")}
                    // onClick={viewRecord(params)}
                    showInMenu
                />,
            ],
        },
    ];
    const handleDeleteBaoGia = async () => {
        console.log(rowSelectionModel);
        let check = true;
        for (let i = 0; i < rowSelectionModel.length; i++) {
            const row = rowSelectionModel[i];
            check = await callDeleteBaoGia(row);
        }
        if (check) {
            showNoti("error", t("delete") + " " + t("noti.error").toLowerCase());
        } else {
            showNoti("error", t("delete") + " " + t("noti.error").toLowerCase());
        }
    };
    const handleCancelBaoGia = async () => {
        console.log(rowSelectionModel);
        let check = true;
        for (let i = 0; i < rowSelectionModel.length; i++) {
            const row = rowSelectionModel[i];
            check = await callCancelBaoGia(row);
        }
        if (check) {
            showNoti("error", t("action.cancel") + " " + t("noti.error").toLowerCase());
        } else {
            showNoti("error", t("action.cancel") + " " + t("noti.error").toLowerCase());
        }
    };

    return (
        <>
            {contextHolder}
            <FormTimKiemBG
                activeKey={activeKey}
                dmTrangThaiId={dmTrangThaiId}
                setDmTrangThaiId={setDmTrangThaiId}
                dataTrangThaiAll={dataTrangThaiAll}
                dataSearch={dataSearch}
                setDataSearch={setDataSearch}
                onSearch={handleSearch}
                onReset={handleResetDataSearch}
            />
            <Row style={{ marginTop: "1%" }}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <DataGrid
                        onRowDoubleClick={(record) => handleEditBaoGia(record.row)}
                        rows={dataSource}
                        columns={columns}
                        checkboxSelection={rowSelectionModel}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            newRowSelectionModel.length > 0 ? setVsFooter(true) : setVsFooter(false);
                            setRowSelectionModel(newRowSelectionModel);
                        }}
                        pageSizeOptions={[5, 10, 25, 50]}
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 5, page: 0 },
                            },
                        }}
                        rowHeight={120}
                        sx={{
                            "& .MuiDataGrid-cell": {
                                borderRight: "1px solid rgba(224, 224, 224, 1)",
                            },
                            "& .MuiDataGrid-row": {
                                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                            },
                            "& .MuiDataGrid-columnHeader": {
                                borderRight: "1px solid rgba(224, 224, 224, 1)",
                                backgroundColor: "#e2e2e2",
                            },
                            "& .MuiDataGrid-columnHeaderTitle ": {
                                fontWeight: "bold",
                            },
                        }}
                        slots={{
                            footer: () => (
                                <>
                                    <GridPagination />
                                    {vsFooter ? (
                                        <Box>
                                            <Row>
                                                <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
                                                <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                                                    <ClearIcon onClick={() => setVsFooter(false)} />
                                                </Col>
                                                <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                    {rowSelectionModel.length} selected
                                                </Col>
                                                <Col xs={3} sm={3} md={3} lg={3} xl={3}></Col>
                                                <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                    <Button onclick={handleChuyenDieuHanh}>
                                                        {t("Executivetransfer")}
                                                    </Button>
                                                </Col>
                                                <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                    <Button onClick={handleCancelBaoGia}>{t("Cancelquote")}</Button>
                                                </Col>
                                                <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                                    <Button>{t("Replication")}</Button>
                                                </Col>
                                                <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        onClick={handleDeleteBaoGia}
                                                    >
                                                        {t("delete")}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Box>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            ),
                        }}
                        localeText={i18n.language === "vi" ? viVN.components.MuiDataGrid.defaultProps.localeText : enUS}
                    />
                </Col>
            </Row>
        </>
    );
}

export default QuanLyBaoGia;
