import { getStorage } from "../api/BaseApi";

export const LANGUAGES = [
  { label: "VietNam", code: "vi" },
  { label: "English", code: "en" },
];

export const API_BASE_URL = "https://be.stour.com.vn/api/";
export const key_crypt = "madJP9WPyVW0nzKzwv8ee4r15gB5TG4P2024";
// export const API_BASE_URL = "http://66.42.62.176:8080/api/";
export const ACCESS_TOKEN = "accessToken";
export const Bearer = "Bearer ";
export const HEADERS = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};
export const data_getDM = JSON.stringify({
  hieu_luc: null,
});
export const header_token_download = {
  responseType: "arraybuffer",
  Authorization: `Bearer ${getStorage(ACCESS_TOKEN)}`,
};
// export const header_token = {
//     "Content-Type": "application/json",
//     "Access-Control-Allow-Origin": "*",
//     Authorization: `Bearer ${getStorage(ACCESS_TOKEN)}`,
// };
export const header_token_import = {
  "Content-Type": "multipart/form-data",
  Authorization: `Bearer ${getStorage(ACCESS_TOKEN)}`,
};
export const Menu_Item = {
  1: "SMART TOUR",
  admin: "ADMIN",
  dm: "category",
  ncc: "supplier",
  new: "add",
  edit: "edit",
  "ti-gia": "exchangeRate",
  "loai-hinh-dl": "typeTourism",
  "loai-tour": "typeTour",
  "dia-diem": "location",
  "khach-san": "hotel",
  "nha-hang": "restaurant",
  oto: "oto",
  visa: "visa",
  "du-thuyen": "yacht",
  "may-bay": "plane",
  "ve-thuyen": "boatTickets",
  "ve-bus": "busTickets",
  "ve-tau": "trainTickets",
  "dv-khac": "otherServices",
  "huong-dan-vien-tu-do": "freelanceTourGuide",
  "tour-san-pham": "tourSanPham",
  "tour-mo-ban": "tourMoBan",
  "dich-vu": "service",
  "chiet-tinh-tour": "chietTinhTour",
  "bao-gia": "baoGia",
  "them-moi-bao-gia": "addNewQuotation",
  "dat-dich-vu": "booking",
  "dieu-hanh": "serviceManagement",
  "ql-nguoi-dung": "userManagement",

  "thong-tin-chung": "hotel.generalInfo",
  "dich-vu-tour": "service",
  "thong-tin-tour": "thongTinTour",
  "sale-rate": "saleRate",
  "dich-vu-le": "dichVuLe",
  "tong-chi-phi": "tour.tongChiPhi",
  "danh-sach-doan": "danhSachDoan",
  "thuc-thu": "thucThu",
  "chi-phi-uoc-tinh": "chiPhiUocTinh",
  "chi-phi-khac": "chiPhiKhac",
  "lich-su": "lichSu",
  "ghi-chu": "notes",
  "chuong-trinh-tour": "chuongTrinhTour",
  "ql-hanh-trinh": "quanLyHanhTrinh",
};
export const Ncc_ID = {
  "khach-san": 1,
  oto: 2,
  "nha-hang": 3,
  "du-thuyen": 4,
  "huong-dan-vien": 5,
  "may-bay": 6,
  "ve-thuyen": 7,
  visa: 8,
  "ve-thang-canh": 9,
  "ve-bus": 10,
  "ve-tau": 11,
  "dich-vu-khac": 12,
  "land-tour": 13,
  "huong-dan-vien-tu-do": 14,
};
export const maxFile = 5;
export const maxFile_Tour = 3;
export const maxSize = 10;

export const maPhongDon = "SGL";
export const maPhuPhi = "EX";

export const soChoPax = {
  4: "01 Pax",
  7: "05 Pax",
  16: "10 Pax",
  24: "15-20 Pax",
  29: "25 Pax",
};
export const VIEW_NCC = ["EXCUTIVE_MANAGER", "OPERATOR", "DIRECTOR"];
export const VIEW_DV = ["SALE", "DIRECTOR"];
export const VIEW_BG = ["SALE", "DIRECTOR"];
export const VIEW_BK = ["SALE", "DIRECTOR"];
export const VIEW_DHDV = ["EXCUTIVE_MANAGER", "OPERATOR"];
export const VIEW_KT = ["ACCOUNTANT"];
export const VIEW_DM = ["DIRECTOR", "SYSTEM_MANAGER"];
export const ROLE_MANAGER = {
  EXCUTIVE_MANAGER: "EXCUTIVE_MANAGER",
  OPERATOR: "OPERATOR",
  DIRECTOR: "DIRECTOR",
  SALE: "SALE",
  ACCOUNTANT: "ACCOUNTANT",
  SYSTEM_MANAGER: "SYSTEM_MANAGER",
  ROLE_ADMIN: "ROLE_ADMIN",
};
export const key_menu = {
  "": "1",
  dm: "3",
  "ti-gia": "4",
  "loai-hinh-dl": "5",
  "loai-tour": "6",
  "dia-diem": "7",
  ncc: "8",
  "dich-vu": "9",
  "tour-san-pham": "10",
  "tour-mo-ban": "11",
  "bao-gia": "12",
  "dat-dich-vu": "13",
  "dieu-hanh": "14",
  "ql-nguoi-dung": "15",
};
export const TOUR_TG_AP_DUNG = "TOUR_TG_AP_DUNG";
export const TOUR_KHOANG_KHACH = "TOUR_KHOANG_KHACH";
export const DM_DICH_VU = {
  "Khách sạn": 1,
  "Xe ô tô": 2,
  "Nhà hàng": 3,
};
export const DV_XE_OTO= "Xe ô tô"
export const DV_KS= "Khách sạn"
export const DV_NH= "Nhà hàng"
export const NH_Buffet="Buffet"
export const NH_AN_TRUA="Ăn trưa"
export const NH_AN_SANG="Ăn sáng"
export const NH_AN_TOI="Ăn tối"
export const TIME_DURATION = 3