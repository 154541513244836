import React, { useEffect, useState } from "react";
import { Col, Modal, Row, Space, Divider, Table, notification } from "antd";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import Dm_gia_ap_dung from "../../../../../api/category/dm_gia_ap_dung";
import ModalThongTinNccOto from "./ModalThongTinNccOto";
import otoService from "../../../../../api/tour/oto/oto.service";
import { InputAdornment, MenuItem, TextField } from "@mui/material";
import { TIME_DURATION } from "../../../../../constants";
import "../ncc/ModalNcc.css";

const ModalNccOto = ({
  isOpen,
  isAdd,
  onSave,
  onCancel,
  tourMapChietTinhId,
  danhSachKhoangKhach,
  tourChuongTrinhId,
  tourQuanLyKhoangKhach,
  thoiGianAD,
  tourThongTinChungId,
  tourThoiGianApDungId,
  tourChietTinhId,
  tabChietTinhId,
}) => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const [dataTable, setDataTable] = useState([]);
  const [dataTableInit, setDataTableInit] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [dmGia, setDmGia] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [khoangKhachDuplicate, setKhoangKhachDuplicate] = useState([]);
  const [itemSelect, setItemSelect] = useState({});

  useEffect(() => {
    let data = {};
    Dm_gia_ap_dung.getDmGiaApDung(data)
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data.data;
          setDmGia(data);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
        if (response.status === 401) {
        }
      });
    let initDataTable = [];
    if (danhSachKhoangKhach && danhSachKhoangKhach.length > 0) {
      let len = danhSachKhoangKhach.length;
      for (let i = 0; i < len; i++) {
        let tourQuanLy = tourQuanLyKhoangKhach.find(
          (item) => item.tourQuanLyKhoangKhachId === danhSachKhoangKhach[i].id
        );
        if (tourQuanLy?.parentTranxOtoId === 0 || isAdd) {
          initDataTable.push({
            stt: i + 1,
            tourQuanLyKhoangKhachId: danhSachKhoangKhach[i].id,
            tourChietTinhId: danhSachKhoangKhach[i].tourChietTinhId,
            dmSoChoId: danhSachKhoangKhach[i].dmSoChoId,
            soCho: danhSachKhoangKhach[i].dmSoCho,
            dmGiaApDungId: 1,
            tongSoKm: "",
            id: i,
          });
        }
      }
    }
    let arr = initDataTable.map((item) => item.soCho);
    let findDuplicates = arr.filter(
      (item, index) => arr.indexOf(item) !== index
    );
    setKhoangKhachDuplicate(findDuplicates);
    if (isAdd) {
      setDataTable(initDataTable);
      setDataTableInit(JSON.parse(JSON.stringify(initDataTable)));
    } else {
      getCauHinhOtoTheoMap(
        {
          tourMapChietTinhChuongTrinhId: tourMapChietTinhId,
          tranxChietTinhOtoId: 0, //0 là mặc định, != 0 lấy theo id
        },
        initDataTable
      );
    }
  }, [tourMapChietTinhId, isAdd, danhSachKhoangKhach, tourQuanLyKhoangKhach]);

  const getCauHinhOtoTheoMap = (dataReq, initDataTable) => {
    otoService
      .getCauHinhOtoTheoMap(dataReq)
      .then((response) => {
        if (response.status === 200) {
          let data = response.data.data;
          data.forEach((item) => {
            let idx = initDataTable.findIndex(
              (it) =>
                it.tourQuanLyKhoangKhachId === item.tourQuanLyKhoangKhachId
            );
            if (idx !== -1)
              initDataTable[idx] = {
                ...item,
                id: idx,
                stt: idx + 1,
                tongChiPhi: item.giaDichVu * item.tongSoKm,
              };
          });
          console.log("dataCauHinhOto", initDataTable);
          setDataTable(initDataTable);
          setDataTableInit(JSON.parse(JSON.stringify(initDataTable)));
        }
      })
      .catch((response) => {
        console.log("Error response: ====" + response);
      });
  };

  const onFinish = (e) => {
    e.preventDefault();
    if (isUpdate) {
      if (isAdd) {
        saveCauHinhOto();
      } else {
        updateCauHinhOto();
      }
    } else {
      handleNoti("error", t("noti.saveWarning"));
    }
  };

  const saveCauHinhOto = () => {
    let dataReq = getDataReqSaveChietTinhOto();
    console.log("@@@@@@ dataReq: ", JSON.stringify(dataReq));
    const infoErr = validateInfoDataSave(dataReq, "save");
    if (infoErr.check) {
      handleNoti("error", t(infoErr.message));
    } else {
      otoService
        .saveCauHinhOto(dataReq)
        .then((response) => {
          if (response.status === 200 && response.data.code === "00") {
            handleNoti(
              "success",
              t("add") + " " + t("noti.success").toLowerCase()
            );
            setIsUpdate(false);
            onSave();
          } else {
            handleNoti("error", t("add") + " " + t("noti.error").toLowerCase());
          }
        })
        .catch((response) => {
          handleNoti("error", t("system.error"));
        });
    }
  };

  const updateCauHinhOto = () => {
    let dataReq = getDataReqUpdateChietTinhOto();
    console.log("@@@@@@ dataReq: ", dataReq);
    console.log("@@@@@@ dataReq: ", JSON.stringify(dataReq));
    const infoErr = validateInfoDataSave(dataReq, "update");
    if (infoErr.check) {
      handleNoti("error", t(infoErr.message));
    } else {
      otoService
        .updateCauHinhOtoTheoMap(dataReq)
        .then((response) => {
          if (response.status === 200 && response.data.code === "00") {
            handleNoti(
              "success",
              t("edit") + " " + t("noti.success").toLowerCase()
            );
            setIsUpdate(false);
            onSave();
          } else {
            handleNoti(
              "error",
              t("edit") + " " + t("noti.error").toLowerCase()
            );
          }
        })
        .catch((response) => {
          handleNoti("error", t("system.error"));
        });
    }
  };

  const validateInfoDataSave = (dataReq, action) => {
    const infoErr = { check: false, message: "" };
    if (
      (action === "save" &&
        dataReq.tabChietTinh[0].tourDichVu?.thongTinOtoChiTiets.length === 0) ||
      (action === "update" &&
        dataReq.tourDichVu?.thongTinOtoChiTiets.length === 0)
    ) {
      infoErr.check = true;
      infoErr.message = "noti.saveWarning";
      return infoErr;
    } else if (
      (action === "save" &&
        dataReq.tabChietTinh[0].tourDichVu?.thongTinOtoChiTiets.some(
          (it) => it.hinhThucGiaId === 2 && (!it.tongSoKm || it.tongSoKm <= 0)
        )) ||
      (action === "update" &&
        dataReq.tourDichVu?.thongTinOtoChiTiets.some(
          (it) => it.hinhThucGiaId === 2 && (!it.tongSoKm || it.tongSoKm <= 0)
        ))
    ) {
      infoErr.check = true;
      infoErr.message = "noti.errorTongSoKm";
      return infoErr;
    }
    return infoErr;
  };

  const handleNoti = (type, description) => {
    return api[type]({
      message: t("stour"),
      description: description,
      duration: TIME_DURATION,
    });
  };

  const getDataReqSaveChietTinhOto = () => {
    let dataSave = dataTable.filter(
      (item) => item.tenNcc !== null && item.tenNcc !== undefined
    );
    let thongTinOtoChiTiets = dataSave.map((data) => {
      return {
        tourQuanLyKhoangKhachId: data.tourQuanLyKhoangKhachId,
        nccThongTinChungId: data.nccThongTinChungId,
        nccDichVuOtoId: data.nccDichVuOtoId,
        hinhThucGiaId: data.dmHinhThucGiaId,
        tranxOtoId: data.tranxOtoId,
        isVat: data.isVat,
        dmTyGiaId: data.dmTyGiaId,
        dmVatId: data.dmVatId ? data.dmVatId : 1,
        tongSoKm: data.tongSoKm,
        dmGiaApDungId: data.dmGiaApDungId,
        moTa: data.moTa,
      };
    });
    let tourDichVu = {
      tourChuongTrinhId: tourChuongTrinhId,
      tourMapChietTinhChuongTrinhId: tourMapChietTinhId,
      thongTinOtoChiTiets: thongTinOtoChiTiets,
    };

    let tabChietTinh = [{ id: tabChietTinhId, tourDichVu: tourDichVu }];

    let dataReq = {
      tourThongTinChungId: tourThongTinChungId,
      tourChietTinhId: tourChietTinhId,
      tourThoiGianApDungId: tourThoiGianApDungId,
      tabChietTinh: tabChietTinh,
    };
    return dataReq;
  };

  const getDataReqUpdateChietTinhOto = () => {
    let dataUpdate = dataTable.filter(
      (item) => item.tenNcc !== null && item.tenNcc !== undefined
    );
    let thongTinOtoChiTiets = dataUpdate.map((data) => {
      return {
        tranxChietTinhOtoId: data.tranxChietTinhOtoId
          ? data.tranxChietTinhOtoId
          : 0,
        tourQuanLyKhoangKhachId: data.tourQuanLyKhoangKhachId,
        nccThongTinChungId: data.nccThongTinChungId,
        nccDichVuOtoId: data.nccDichVuOtoId,
        hinhThucGiaId: data.dmHinhThucGiaId,
        tranxOtoId: data.tranxOtoId,
        isVat: data.isVat,
        dmTyGiaId: data.dmTyGiaId,
        dmVatId: data.dmVatId,
        tongSoKm: data.tongSoKm,
        dmGiaApDungId: data.dmGiaApDungId,
        moTa: data.moTa,
      };
    });
    let tourDichVu = {
      tourMapChietTinhChuongTrinhId: tourMapChietTinhId,
      tourChuongTrinhId: tourChuongTrinhId,
      thongTinOtoChiTiets: thongTinOtoChiTiets,
    };

    let dataReq = {
      tourThongTinChungId: tourThongTinChungId,
      tourChietTinhId: tourChietTinhId,
      tourThoiGianApDungId: tourThoiGianApDungId,
      tourDichVu: tourDichVu,
    };
    return dataReq;
  };

  const handleChangeDataServiceContainDuplicate = (dataUpdate) => {
    dataUpdate.forEach((item) => {
      changeDataTable(item.soCho, item);
    });
    console.log(dataTable);
    let dataChange = [...dataTable];
    setIsUpdate(true);
    setDataTable(dataChange);
  };

  const handleChangeDataService = (dataUpdate) => {
    dataUpdate.forEach((item) => {
      if (khoangKhachDuplicate.includes(item.soCho)) {
        let idx = -1;
        if (itemSelect.soCho === item.soCho) {
          idx = dataTable.findIndex(
            (it) => it.stt === itemSelect.stt && it.soCho === item.soCho
          );
          changeDataTableByIndex(idx, item);
        } else {
          idx = dataTable.findIndex((it) => it.soCho === item.soCho);
          changeDataTableByIndex(idx, item);
        }
      } else {
        changeDataTable(item.soCho, item);
      }
    });
    console.log(dataTable);
    let dataChange = [...dataTable];
    setIsUpdate(true);
    setDataTable(dataChange);
  };

  const changeDataTableByIndex = (index, dataChange) => {
    dataTable[index].tenNcc = dataChange.tenNcc;
    dataTable[index].tenDichVu = dataChange.tenDichVu;
    dataTable[index].nccDichVuOtoId = dataChange.nccDichVuOtoId;
    dataTable[index].nccThongTinChungId = dataChange.nccThongTinChungId;
    dataTable[index].tranxOtoId = dataChange.tranxOtoId;
    dataTable[index].hinh_thuc_gia = dataChange.hinhThucGia;
    dataTable[index].dmHinhThucGiaId = dataChange.hinhThucGiaId;
    dataTable[index].tongSoKm = dataChange.kmUocTinh;
    dataTable[index].giaDichVu = dataChange.giaNet;
    dataTable[index].tongChiPhi = dataChange.giaNet * dataChange.kmUocTinh;
    dataTable[index].vat = dataChange.vat;
    dataTable[index].isVat = dataChange.isVat;
    dataTable[index].dmVatId = dataChange.dmVatId;
    dataTable[index].dmTyGiaId = dataChange.dmTyGiaId;
  };

  const changeDataTable = (key, dataChange) => {
    for (var i in dataTable) {
      if (dataTable[i].soCho === key) {
        dataTable[i].tenNcc = dataChange.tenNcc;
        dataTable[i].tenDichVu = dataChange.tenDichVu;
        dataTable[i].nccDichVuOtoId = dataChange.nccDichVuOtoId;
        dataTable[i].nccThongTinChungId = dataChange.nccThongTinChungId;
        dataTable[i].tranxOtoId = dataChange.tranxOtoId;
        dataTable[i].hinh_thuc_gia = dataChange.hinhThucGia;
        dataTable[i].dmHinhThucGiaId = dataChange.hinhThucGiaId;
        dataTable[i].tongSoKm = dataChange.kmUocTinh;
        dataTable[i].giaDichVu = dataChange.giaNet;
        dataTable[i].tongChiPhi = dataChange.giaNet * dataChange.kmUocTinh;
        dataTable[i].vat = dataChange.vat;
        dataTable[i].isVat = dataChange.isVat;
        dataTable[i].dmVatId = dataChange.dmVatId;
        dataTable[i].dmTyGiaId = dataChange.dmTyGiaId;
      }
    }
  };

  const handleCancels = (e) => {
    e.preventDefault();
    onCancel(false);
  };

  const handleClear = () => {
    let dataInit = JSON.parse(JSON.stringify(dataTableInit));
    setDataTable(dataInit);
  };

  const showModal = (row) => {
    console.log(row);
    setItemSelect(row);
    setIsShow(true);
  };

  const handleChangeGiaApDung = (id, value) => {
    let data = [...dataTable];
    let index = data.findIndex((it) => it.id === id);
    if (index !== -1) {
      data[index].dmGiaApDungId = value;
      setIsUpdate(true);
      setDataTable(data);
    }
  };

  const onChangeNumberOfKm = (id, value) => {
    let data = [...dataTable];
    let index = data.findIndex((it) => it.id === id);
    if (index !== -1) {
      data[index].tongSoKm = value < 0 ? 0 : value;
      data[index].tongChiPhi = data[index].tongSoKm * data[index].giaDichVu;
      setIsUpdate(true);
      setDataTable(data);
    }
  };

  const handleData = (value) => {
    let number = 0;
    if (value !== undefined) {
      number = parseInt(value);
    }
    return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  const columns = [
    {
      dataIndex: "stt",
      key: "stt",
      title: t("stt"),
      width: "4%",
      editable: false,
      align: "center",
    },
    {
      dataIndex: "soCho",
      key: "soCho",
      title: t("number"),
      width: "8%",
      align: "center",
      editable: false,
    },
    {
      dataIndex: "tenNcc",
      key: "tenNcc",
      title: t("supplier"),
      width: "15%",
      align: "center",
      editable: false,
      render: (value, record) => {
        if (record.soCho != null) {
          return (
            <Row>
              <Col span={20}>{value}</Col>
              <Col span={3}>
                <BorderColorOutlinedIcon
                  // style={{ marginLeft: "5px" }}
                  fontSize="small"
                  onClick={() => showModal(record)}
                />
              </Col>
            </Row>
          );
        }
      },
    },
    {
      dataIndex: "tenDichVu",
      key: "tenDichVu",
      title: t("service"),
      type: "number",
      width: "15%",
      align: "center",
      editable: false,
    },
    {
      dataIndex: "hinh_thuc_gia",
      key: "hinh_thuc_gia",
      title: t("formOfPricing"),
      editable: false,
      type: "number",
      align: "center",
      width: "10%",
    },
    {
      dataIndex: "tongSoKm",
      key: "tongSoKm",
      title: t("numberOfKm"),
      type: "number",
      editable: true,
      align: "center",
      width: "20%",
      render: (_, record) => {
        return (
          <TextField
            id="outlined-number"
            size="small"
            type="number"
            value={record.tongSoKm}
            onChange={(e) => {
              onChangeNumberOfKm(record.id, e.target.value);
            }}
            disabled={record.dmHinhThucGiaId === 1 || !record.dmHinhThucGiaId}
            InputProps={{
              endAdornment: <InputAdornment position="end">Km</InputAdornment>,
            }}
          />
        );
      },
    },
    {
      dataIndex: "dmGiaApDungId",
      key: "dmGiaApDungId",
      title: t("priceApply"),
      align: "center",
      editable: true,
      width: "20%",
      render: (_, row) => {
        return (
          <TextField
            select
            fullWidth
            // style={{ width: "70%" }}
            size="small"
            value={row.dmGiaApDungId}
            onChange={(e) => handleChangeGiaApDung(row.id, e.target.value)}
            native
            autoFocus
          >
            {dmGia?.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.gia_ap_dung}
              </MenuItem>
            ))}
          </TextField>
        );
      },
    },
    {
      dataIndex: "giaDichVu",
      key: "giaDichVu",
      title: t("priceOfService"),
      align: "center",
      // type: "number",
      width: "10%",
      editable: false,
      render: (_, record) => {
        return handleData(record.giaDichVu);
      },
    },
    {
      dataIndex: "tongChiPhi",
      key: "tongChiPhi",
      title: t("tour.tongChiPhi"),
      align: "center",
      type: "number",
      editable: false,
      width: "10%",
      render: (_, record) => {
        return handleData(record.tongChiPhi);
      },
    },
    {
      dataIndex: "vat",
      key: "vat",
      title: t("tax"),
      align: "center",
      editable: false,
      width: "10%",
    },
  ];
  const onHuy = () => {
    setIsShow(false);
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={t("tour.oto.capNhatTatCaDichVu")}
        open={isOpen}
        onOk={onFinish}
        onCancel={handleCancels}
        footer={null}
        className="custom-modal-ncc"
        // width={1300}
        // height={800}
      >
        <Divider />
        <ModalThongTinNccOto
          isOpen={isShow}
          // onSaves={onChon}
          onCancels={onHuy}
          isCapNhatAll={true}
          onUpdateServiceContainDuplicate={
            handleChangeDataServiceContainDuplicate
          }
          onUpdateService={handleChangeDataService}
          khoangKhachDuplicate={khoangKhachDuplicate}
          thoiGianAD={thoiGianAD}
        />
        <Box component="form" noValidate>
          <Row style={{ marginTop: "10px" }}></Row>
          <Table
            columns={columns}
            dataSource={dataTable}
            style={{ width: "100%" }}
            // scroll={{ x: "110%" }}
          />
          <Row style={{ marginTop: 6 }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}></Col>
            <Col xs={24} sm={24} md={24} lg={18} xl={10}>
              <Space>
                <Button
                  variant="contained"
                  onClick={onFinish}
                  type="submit"
                  startIcon={<SaveIcon />}
                >
                  {t("action.ok")}
                </Button>
                <Button
                  style={{
                    backgroundColor: "#898989",
                    color: "white",
                  }}
                  key="reset"
                  startIcon={<RestartAltIcon />}
                  onClick={(e) => handleClear(e)}
                >
                  {t("action.reset")}
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<DeleteIcon />}
                  onClick={handleCancels}
                  key="clear"
                >
                  {t("action.cancel")}
                </Button>
              </Space>
            </Col>
          </Row>
        </Box>
      </Modal>
    </>
  );
};
export default ModalNccOto;
