import React, {useEffect, useState} from "react";
import {Space, Input, Modal, Divider, Form, Row, Col, DatePicker, Card, Switch, Affix, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {MinusCircleOutlined} from "@ant-design/icons";
import axios from "axios";
import {API_BASE_URL, data_getDM} from "../../../../../constants";
import "../../../../../assets/css/supplier/res/dvCc/modal.css";
import moment from "moment";
import dayjs from "dayjs";
import authHeader from "../../../../../api/auth_header";
import TableModalGP from "./TableModalGP";
import {Button, MenuItem, TextField} from "@mui/material";
import RestartAlt from "@mui/icons-material/RestartAlt";
import Save from "@mui/icons-material/Save";
import Delete from "@mui/icons-material/Delete";

const {RangePicker} = DatePicker;
const { Text } = Typography;
const ModalPrAction = ({isOpen, handleCancel, handleFinish, record, action}) => {
    const [open, setOpen] = useState(isOpen);
    const [form] = Form.useForm();
    const {t} = useTranslation();
    const [tiGia, setTiGia] = useState([]);
    const [dataSourceTable, setDataSourceTable] = useState([]);
    const [idTable, setIdTable] = useState([]);
    const [tienId, setTienId] = useState();
    const [dataSource, setDataSource] = useState();
    const [id, setId] = useState("");
    const [nccThongTinChungId, setNccThongTinChungId] = useState("");
    const [maDichVu, setMaDichVu] = useState("");
    const [dmTyGiaId, setDmTyGiaId] = useState(1);
    const [tenDichVu, setTenDichVu] = useState("");
    const [ghiChu, setGhiChu] = useState("");
    const [maNgoaiTe, setMaNgoaiTe] = useState("");
    const [loaiTien, setLoaiTien] = useState("");

    const [maDichVuErr, setMaDichVuErr] = useState(false);
    const [tenDichVuErr, setTenDichVuErr] = useState(false);
    const [timeErr, setTimeErr] = useState(false);

    useEffect(() => {
        // debugger;
        let apiData = {
            id: record.isDouble ? record.nccDichVuNhId : record.id,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/dvNhaHang/getDataById",
            data: apiData,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    let dataArr = [];
                    setTienId(data.maNgoaiTe);
                    var items = [];
                    if (data.tranxDvNhaHangTimeRequests.length > 0) {
                        var dataSource = [];
                        let dataFit = data.tranxDvNhaHangTimeRequests;
                        for (let k = 0; k < dataFit.length; k++) {
                            var tuNgay = dataFit[k].tuNgay.split("/");
                            var denNgay = dataFit[k].denNgay.split("/");
                            items.push({
                                id: dataFit[k].id,
                                nccDichVuNhId: dataFit[k].nccDichVuNhId,
                                rangeTime: [
                                    dayjs(new Date(tuNgay[2], tuNgay[1] - 1, tuNgay[0])),
                                    dayjs(new Date(denNgay[2], denNgay[1] - 1, denNgay[0])),
                                ],
                                trangThaiBooking: dataFit[k].trangThaiBooking,
                                foc: dataFit[k].foc,
                                dieuKienFoc: dataFit[k].dieuKienFoc,
                            });
                            dataArr.push({
                                id: dataFit[k].id,
                                nccDichVuNhId: dataFit[k].nccDichVuNhId,
                                rangeTime: [
                                    dayjs(new Date(tuNgay[2], tuNgay[1] - 1, tuNgay[0])),
                                    dayjs(new Date(denNgay[2], denNgay[1] - 1, denNgay[0])),
                                ],
                                trangThaiBooking: dataFit[k].trangThaiBooking,
                                foc: dataFit[k].foc,
                                dieuKienFoc: dataFit[k].dieuKienFoc,
                                children: [
                                    {
                                        id: 0,
                                        rowKey: Math.random() * (100 - 1),
                                        typePrice: "Fit",
                                        fitNgayThuongNet: dataFit[k].fitNgayThuongNet,
                                        fitNgayThuongGiaBan: dataFit[k].fitNgayThuongGiaBan,
                                        fitCuoiTuanNet: dataFit[k].fitCuoiTuanNet,
                                        fitCuoiTuanGiaBan: dataFit[k].fitCuoiTuanGiaBan,
                                    },
                                ],
                            });
                            dataSource.push({
                                fit: {
                                    fitNgayThuongNet: dataFit[k].fitNgayThuongNet,
                                    fitNgayThuongGiaBan: dataFit[k].fitNgayThuongGiaBan,
                                    fitCuoiTuanNet: dataFit[k].fitCuoiTuanNet,
                                    fitCuoiTuanGiaBan: dataFit[k].fitCuoiTuanGiaBan,
                                },
                                key: k,
                            });
                        }
                        form.setFieldsValue({
                            items: items,
                        });
                    }
                    setId(data.id);
                    setNccThongTinChungId(data.nccThongTinChungId);
                    setMaDichVu(data.maDichVu);
                    setDmTyGiaId(data.dmTyGiaId);
                    setTenDichVu(data.tenDichVu);
                    setGhiChu(data.ghiChu);
                    setMaNgoaiTe(data.maNgoaiTe);
                    setLoaiTien(data.loaiTien);
                    setDataSource(dataSource);
                    setDataSourceTable(dataArr);
                    setIdTable(data.id);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });

    }, [record]);
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmTyGia",
            data: data_getDM,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;
                    let dataLst = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataLst.push({
                                value: data[i].id,
                                label: data[i].loai_tien,
                            });
                        }
                        setTiGia(dataLst);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    }, []);

    const onFinish = async (values) => {
        setTimeErr(false);
        setMaDichVuErr(false);
        setTenDichVuErr(false);
        var error = false;
        debugger;
        if (!maDichVu) {
            setMaDichVuErr(true);
            error = true;
        }
        if (!tenDichVu) {
            setTenDichVuErr(true);
            error = true;
        }
        form.resetFields();
        setOpen(false);
        var timeErr = [];
        var tranxDvNhaHangTimeRequests = [];
        if (values.items) {
            for (let i = 0; i < values.items.length; i++) {
                const element = values.items[i];
                var time = !element.rangeTime;

                timeErr.push({
                    time: time,
                });
                if (!time) {
                    var index = dataSource.findIndex((item) => item.key === i);
                    tranxDvNhaHangTimeRequests.push({
                        nccDichVuNhId: element.nccDichVuNhId !== undefined ? element.nccDichVuNhId : idTable,
                        id: element.id,
                        tuNgay: element.rangeTime[0].format("DD/MM/YYYY"),
                        denNgay: element.rangeTime[1].format("DD/MM/YYYY"),
                        fitNgayThuongNet: dataSource[index].fit.fitNgayThuongNet,
                        fitNgayThuongGiaBan: dataSource[index].fit.fitNgayThuongGiaBan,
                        fitCuoiTuanNet: dataSource[index].fit.fitCuoiTuanNet,
                        fitCuoiTuanGiaBan: dataSource[index].fit.fitCuoiTuanGiaBan,
                        trangThaiBooking: element.trangThaiBooking ? element.trangThaiBooking : false,
                        foc: element.foc,
                        dieuKienFoc: element.dieuKienFoc,
                    });
                } else error = true;
            }
        }
        setTimeErr(timeErr);
        if (error) return;
        let data = {
            id: id,
            nccThongTinChungId: nccThongTinChungId,
            maDichVu: maDichVu,
            tenDichVu: tenDichVu,
            dmTyGiaId: dmTyGiaId ? dmTyGiaId : 1,
            loaiTien: tiGia[dmTyGiaId] ? tiGia[dmTyGiaId].label : "",
            ghiChu: ghiChu ? ghiChu : "",
            tranxDvNhaHangTimeRequests: tranxDvNhaHangTimeRequests,
        };
        let check = await handleFinish(open, data);
        if (check) {
            handleClear();
        }
    };
    const handleCancels = () => {
        handleClear();
        handleCancel();
    };
    const formItemLayoutDate = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    };
    const dataRender = () => {
        let html;
        html = (
            <Space direction="vertical" size="small" style={{display: "flex"}}>
                <Form.Item name="id" hidden>
                    <Input/>
                </Form.Item>
                <Form.Item name="nccThongTinChungId" hidden>
                    <Input/>
                </Form.Item>
                <div className="">
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <TextField
                                        label={t("res.code") + " *"}
                                        value={maDichVu ? maDichVu : ""}
                                        fullWidth
                                        onChange={(e) => setMaDichVu(e.target.value)}
                                        error={maDichVuErr}
                                        helperText={
                                            maDichVuErr ? t("validate") + " " + t("res.code").toLowerCase() : ""
                                        }
                                    ></TextField>
                                </Col>
                            </Row>
                            <Row></Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <TextField
                                        sx={{
                                            marginTop: 2,
                                        }}
                                        label={t("res.serviceProvided") + " *"}
                                        value={tenDichVu ? tenDichVu : ""}
                                        fullWidth
                                        onChange={(e) => setTenDichVu(e.target.value)}
                                        error={tenDichVuErr}
                                        helperText={
                                            tenDichVuErr
                                                ? t("validate") + " " + t("res.serviceProvided").toLowerCase()
                                                : ""
                                        }
                                    ></TextField>
                                </Col>
                            </Row>
                            <Row></Row>
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <TextField
                                        sx={{
                                            marginTop: 2,
                                        }}
                                        value={dmTyGiaId}
                                        fullWidth
                                        select
                                        onChange={(e) => setDmTyGiaId(e.target.value)}
                                        label={t("exchangeRate.currency")}
                                    >
                                        {tiGia.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.List name="items">
                                {(fields, {add, remove}) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            rowGap: 16,
                                            flexDirection: "column",
                                        }}
                                    >
                                        {fields.map(({key, name, ...restField}) => (
                                            <Card
                                                size="large"
                                                key={key}
                                                // extra={
                                                //     <CloseOutlined
                                                //         onClick={() => {
                                                //             remove(field.name);
                                                //         }}
                                                //     />
                                                // }
                                            >
                                                <Row>
                                                    <Col xs={14} sm={14} md={14} lg={14} xl={14}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, "rangeTime"]}
                                                            label={t("hotel.priceRoom.applicationTime") + " *"}
                                                            {...formItemLayoutDate}
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message:
                                                                        t("validate") +
                                                                        " " +
                                                                        t(
                                                                            "hotel.priceRoom.applicationTime"
                                                                        ).toLowerCase(),
                                                                },
                                                            ]}
                                                        >
                                                            <RangePicker
                                                                style={{ width: "100%" }}
                                                                placeholder={[t("toDate"), t("fromDate")]}
                                                                disabledDate={(current) => {
                                                                    let customDate = moment().format("DD-MM-YYYY");
                                                                    return (
                                                                        current &&
                                                                        current < moment(customDate, "DD-MM-YYYY")
                                                                    );
                                                                }}
                                                                status={timeErr && timeErr[key]?.time ? "error" : ""}
                                                            />

                                                            {timeErr && timeErr[key]?.time ? (
                                                                <Text type="danger">
                                                                    {t("validate") +
                                                                        " " +
                                                                        t(
                                                                            "hotel.priceRoom.applicationTime"
                                                                        ).toLowerCase()}
                                                                </Text>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                                        {fields.length > 0 ? (
                                                            <MinusCircleOutlined
                                                                className="dynamic-delete-button"
                                                                onClick={() => remove(name)}
                                                            />
                                                        ) : null}
                                                    </Col>
                                                </Row>

                                                <Space
                                                    direction="vertical"
                                                    size="small"
                                                    style={{display: "flex", marginTop: "15px"}}
                                                >
                                                    <Row>
                                                        <TableModalGP
                                                            style={{width: "100%"}}
                                                            keyNum={key}
                                                            setDataEdit={setDataSource}
                                                            dataEdit={dataSource}
                                                            dataS={
                                                                dataSourceTable[key]?.children
                                                                    ? dataSourceTable[key].children
                                                                    : null
                                                            }
                                                        />
                                                    </Row>

                                                    <Row>
                                                        <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, "dieuKienFoc"]}
                                                                rules={[
                                                                    {
                                                                        required: false,
                                                                        message:
                                                                            t("validate") +
                                                                            " " +
                                                                            t(
                                                                                "hotel.roomClass.conditionFoc"
                                                                            ).toLowerCase(),
                                                                    },
                                                                ]}
                                                            >
                                                                <TextField
                                                                    label={t("hotel.roomClass.conditionFoc")}
                                                                    sx={{
                                                                        marginTop: 2,
                                                                    }}
                                                                    fullWidth
                                                                ></TextField>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                                                        <Col
                                                            xs={2}
                                                            sm={2}
                                                            md={2}
                                                            lg={2}
                                                            xl={2}
                                                            style={{marginTop: "30px"}}
                                                        >
                                                            {t("set")}
                                                        </Col>
                                                        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, "foc"]}
                                                                // label={t("hotel.roomClass.foc")}
                                                                rules={[
                                                                    {
                                                                        required: false,
                                                                        message:
                                                                            t("validate") +
                                                                            " " +
                                                                            t("hotel.roomClass.foc").toLowerCase(),
                                                                    },
                                                                ]}
                                                            >
                                                                <TextField
                                                                    sx={{
                                                                        marginTop: 2,
                                                                    }}
                                                                    fullWidth
                                                                ></TextField>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                                                        <Col
                                                            xs={2}
                                                            sm={2}
                                                            md={2}
                                                            lg={2}
                                                            xl={2}
                                                            style={{marginTop: "30px"}}
                                                        >
                                                            {t("hotel.roomClass.foc")}
                                                        </Col>
                                                    </Row>
                                                    <Row style={{marginTop: "15px"}}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, "trangThaiBooking"]}
                                                            label={t("status") + " " + "booking"}
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message:
                                                                        t("validate") +
                                                                        " " +
                                                                        t(
                                                                            "hotel.priceRoom.applicationTime"
                                                                        ).toLowerCase(),
                                                                },
                                                            ]}
                                                            valuePropName="checked"
                                                        >
                                                            <Switch
                                                                defaultChecked={
                                                                    dataSourceTable[key]
                                                                        ? dataSourceTable[key].trangThaiBooking
                                                                        : null
                                                                }
                                                            />
                                                        </Form.Item>
                                                    </Row>
                                                </Space>
                                            </Card>
                                        ))}
                                        <Row>
                                            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                                <Button onClick={() => add()} block>
                                                    {t("add") + " " + t("time").toLowerCase()}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </Form.List>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <TextField
                                label={t("notes")}
                                sx={{
                                    marginTop: 2,
                                }}
                                value={ghiChu ? ghiChu : ""}
                                fullWidth
                                // multiline
                                // rows={5}
                                onChange={(e) => setGhiChu(e.target.value)}
                            ></TextField>
                        </Col>
                    </Row>
                </div>
            </Space>
        );
        return html;
    };

    const handleClear = () => {
        setId(0);
        setMaDichVu("");
        setTenDichVu("");
        setGhiChu("");
        setDataSource("");
        setGhiChu("");
        setMaDichVu(null);
        setTenDichVu(null);
        setDmTyGiaId("");
        form.resetFields();
        handleCancel();
    };
    const onFinishFailed = ({values, errorFields, outOfDate}) => {
        setTimeErr(false);
        setMaDichVuErr(false);
        setTenDichVuErr(false);
        var error = false;
        if (!maDichVu) {
            setMaDichVuErr(true);
            error = true;
        }
        if (!tenDichVu) {
            setTenDichVuErr(true);
            error = true;
        }
    };
    return (
        <div>
            <Modal
                title={action ? t("edit") + " " + t("res.serviceProvided").toLowerCase() : t("res.serviceProvided")}
                open={isOpen}
                // onOk={onFinish}
                onCancel={handleCancels}
                footer={null}
                width={1200}
                // bodyStyle={{ background: "#F5F5F5" }}
            >
                <Divider/>
                <Form
                    form={form}
                    noValidate
                    onFinishFailed={onFinishFailed}
                    onFinish={onFinish}
                    colon={false}
                    layout="vertical"
                    // initialValues={{
                    //     items: [{}],
                    // }}
                >
                    {dataRender()}
                    {action ? (
                        <Form.Item
                            wrapperCol={{
                                span: 12,
                                offset: 12,
                            }}
                        >
                            <Affix offsetBottom={50}>
                                <div
                                    style={{
                                        textAlign: "center",
                                        color: "#fff",
                                        height: 60,
                                        paddingInline: 50,
                                        lineHeight: "60px",
                                        backgroundColor: "rgba(150, 150, 150, 0.2)",
                                        borderRadius: "4px",
                                    }}
                                >
                                    <Space>
                                        <Button
                                            variant="contained"
                                            // onClick={onFinish}
                                            type="submit"
                                            startIcon={<Save/>}
                                        >
                                            {t("action.ok")}
                                        </Button>
                                        <Button
                                            sx={{
                                                backgroundColor: "#898989",
                                                color: "white",
                                            }}
                                            key="reset"
                                            startIcon={<RestartAlt/>}
                                            onClick={(e) => handleClear(e)}
                                        >
                                            {t("action.reset")}
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            startIcon={<Delete/>}
                                            onClick={handleCancels}
                                            key="clear"
                                        >
                                            {t("action.cancel")}
                                        </Button>
                                    </Space>
                                </div>
                            </Affix>
                        </Form.Item>
                    ) : (
                        ""
                    )}
                </Form>
            </Modal>
        </div>
    );
};
export default ModalPrAction;
